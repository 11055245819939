import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js'

const useStyles = makeStyles(styles)

function onInputChange(e) {}

export default function WorkSection() {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem cs={12} sm={12} md={8}>
          <form name="contact" method="POST" data-netlify="true">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onInputChange={onInputChange}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onInputChange={onInputChange}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
                onInputChange={onInputChange}
              />
              <GridItem justifyContent="center" xs={12} sm={12} md={12}>
                <Button type="submit" color="primary">
                  Send Message
                </Button>
              </GridItem>
            </GridContainer>
            <input type="hidden" name="subject" value="Contact" />
            <input type="hidden" name="form-name" value="contact" />
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}
